import React from "react";
import { Link } from "react-router-dom";
import himg from "../../images/slider/salah.png";

const Hero = () => {
  return (
    <>
      <section className="static-hero">
        <div className="hero-container">
          <div className="hero-inner">
            <div className="container row ">
              <div className="hero-text-wrap col">
                <div className="sub-title" style={{ maxWidth: "45rem" }}>
                  <span>مؤسسة الجمل للمحاماة والاستشارات القانونية</span>
                </div>
                <div className="slide-title">
                  <h2 style={{ fontSize: "2rem", lineHeight: "4rem" }}>
                    لدى مؤسسة الجمل للمحاماة فريق عمل من المحترفين المتخصصين في
                    كافة مجالات أعمال المحاماة والاستشارات القانونية على أتم
                    استعداد لدعمكم القانوني وحماية مصالحكم
                  </h2>
                </div>
                <div className="slide-text">
                  <p>
                    تأسست المؤسسة عام 2015 و لما كان الالتزام بالسرية التامة أهم
                    وأول مبادئ مؤسسة الجمل للمحاماة، فإننا نلتزم بعدم الإفصاح
                    والتفاخر بإنجازات المؤسسة في أروقة المحاكم وفي قطاع
                    الاستثمارات الدولية وغيرها من قطاعات الأعمال المتنوعة التي
                    تتولاها المؤسسة، لأننا نؤمن بأن نجاحنا فيما نحققه لصالح
                    عملائنا من إنجازات يُعد أمرًا شديد الخصوصية ويندرج خلف مبدأ
                    الالتزام بالسرية التامة لصالح عملائنا.
                  </p>
                </div>
                <div className="clearfix"></div>
                <div data-swiper-parallax="500" className="slide-btns">
                  <Link to="/about" className="theme-btn-s2">
                    اكتشف المزيد
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className="lawyer-pic top-0 ">
                  <h1
                    className="sub-title"
                    style={{
                      width: "633px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span>خلية خبراء القانون</span>
                  </h1>
                  <div className="lawyer-shape">
                    <img
                      className="position-relative w-100 h-100 "
                      src={himg}
                      alt=""
                    />
                  </div>
                  <div className="exprience w-50">
                    <div
                      className="exprience-left  d-flex align-items-center "
                      style={{ position: "relative" }}
                    >
                      <h6
                        className="fw-bold"
                        style={{
                          fontSize: "8rem",
                          position: "absolute",
                          top: "-100%",
                          left: "3rem",
                          transform: "translateY(-50%)", // Apply the translation here
                        }}
                      >
                        9
                      </h6>
                      <h6 className=" fs-2 fw-bold ">سنوات خبرة</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
