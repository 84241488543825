import React, { Fragment } from "react";
import Hero from "../../components/hero/hero";
import About from "../../components/about/about";
import BlogSection from "../../components/BlogSection/BlogSection";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Practice from "../../components/Practice";
import Footer from "../../components/footer/Footer";
import Testimonial from "../../components/Testimonial/Testimonial";
import CaseStudies from "../../components/CaseStudies/CaseStudies";
import FunFact from "../../components/FunFact";
import Attorney from "../../components/attorneys";
import Consultinencey from "../../components/Consultinencey/Consultinencey";
import abimg from "../../images/about/about3.jpg";
import OurLocation from "../../components/OurLocation/ourLocation";
import HeaderTopbar from "../../components/HeaderTopbar";
import Hero4 from "../../components/hero4/hero4";

const HomePage = () => {
  return (
    <Fragment>
      {/* <HeaderTopbar/> */}
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <Hero4 />
      <Hero />
      <About abimg={abimg} />
      <Practice />
      <Testimonial />
      {/* <CaseStudies vClass={"d-none"} /> */}
      <FunFact />
      <Attorney vClass={"d-none"} />
      {/* <Consultinencey /> */}
      <OurLocation />
      {/* <BlogSection vClass={'d-none'}/> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
