import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServices,
  getSpecialService,
} from "../../store/pages/servicesSlice";
import SidebarArticals from "./Sidebar";
import ContactForm from "../../components/ContactFrom";
import CommentForm from "./CommentForm";
import { getAllArticals } from "../../store/pages/articalsSlice";

const Articals = (props) => {
  const { title } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { articals, loading } = useSelector((state) => state.articals);

  useEffect(() => {
    dispatch(getAllArticals());
    window.scrollTo(10, 0);
  }, [dispatch]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <PageTitle pageTitle={"مقاﻻت قانونية"} pagesub={"مقاﻻت قانونية "} />

      <section className="wpo-practice-section-s2 section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 order-lg-2 order-1 gap-1">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-content d-flex flex-column gap-2">
                  {!loading && articals && articals.length > 0 ? (
                    articals?.slice(0, 1)?.map((artical, index) => (
                      <div className="wpo-section-title" key={index}>
                        <h2>{artical?.content}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: artical?.description,
                          }}
                        ></p>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                <div className="wpo-contact-pg-section section-padding">
                  <div className="wpo-practice-section-s2">
                    <div className="wpo-section-title">
                      <h2> اترك تعليقاً</h2>
                      <p>
                        لن يتم نشر عنوان بريدك الإلكتروني. الحقول الإلزامية مشار
                        إليها بـ *
                      </p>
                    </div>
                    <CommentForm />
                  </div>
                </div>
                {/* <div className="wpo-faq-section">
                                    <h2>اﻻسئلة الشائعة!</h2>
                                    <p>The types of damages that can be claimed in a personal injury suit. Here is a run down of some common types of damagesPellentesque sit tortor eu proin at commodo.</p>
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="wpo-faq-item">
                                                <FaqSection/>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <SidebarArticals />
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default Articals;
