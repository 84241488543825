import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";
import "./style.css";
import logo from "../../images/logo (1).png";

const menus = [
  {
    id: 1,
    title: "الرئيسية",
    link: "/home",
    icon: "fas fa-home",
  },
  {
    id: 2,
    title: "خدمات المؤسسة",
    link: "/practice",
    icon: "fas fa-briefcase",
  },
  {
    id: 3,
    title: "من نحن",
    link: "/about",
    icon: "fa-solid fa-chalkboard-user",
  },
  {
    id: 4,
    title: "فريق العمل",
    link: "/attorneys",
    icon: "fas fa-users",
  },
  {
    id: 5,
    title: "خدماتنا الالكترونية",
    link: "/shop",
    icon: "fas fa-shopping-cart",
  },
  {
    id: 6,
    title: " الملف التعريفى",
    link: "/profile",
    icon: "fas fa-user",
  },

  {
    id: 7,
    title: "تواصل معنا",
    link: "/contact",
    icon: "fas fa-envelope",
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu p-0 ">
          <div className="logo border-bottom  border-gold border-1 mb-2 p-3">
            <Link to="/">
              <img className="img-fluid" src={logo} alt="" />
            </Link>
          </div>
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? "active" : null}
                key={mn}
              >
                {item.submenu ? (
                  <Fragment>
                    {/*<p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}*/}
                    {/*    <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>*/}
                    {/*</p>*/}
                    <Collapse
                      in={item.id === openId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <Link
                                  onClick={ClickHandler}
                                  className="active"
                                  to={submenu.link}
                                >
                                  {submenu.title}
                                </Link>
                              </ListItem>
                            );
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <Link className="active" to={item.link}>
                    <i className={item.icon}></i> {item.title}
                  </Link>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
