import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import Practices from "../../api/Practices";
import {
  getAllServices,
  getSpecialService,
} from "../../store/pages/servicesSlice";
import { useDispatch, useSelector } from "react-redux";

const settings = {
  dots: false,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  rtl: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Practice = (props) => {
  const { services, loading } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ClickHandler = (id) => {
    window.scrollTo(10, 0);
    dispatch(getSpecialService(id));
  };
  useEffect(() => {
    dispatch(getAllServices());
  }, [dispatch]);

  return (
    <section className={`wpo-practice-section-s2 ${props.prClass}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="wpo-section-title">
              <span>خدمات المؤسسة</span>
              <h2>
                استخدام المعرفة القانونية في تقديم الخدمات القانونية المثلى
                لعملائنا بما يضمن مصالحهم ونجاح أعمالهم التجارية وقضاياهم.
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="wpo-practice-wrap">
          <div className="wpo-practice-items">
            <Slider {...settings}>
              {loading ? (
                <div
                  className="spinner-border"
                  style="width: 3rem; height: 3rem;"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : services?.length > 0 ? (
                services?.map((practice, Pitem) => (
                  <div className="wpo-practice-item" key={practice.id}>
                    <div className="wpo-practice-img">
                      <img
                        src={practice.image}
                        alt=""
                        style={{ height: "550px", width: "450px" }}
                      />
                    </div>
                    <div className="wpo-practice-content">
                      <div className="wpo-practice-content-top">
                        <div className="b-top">
                          <div className="b-top-inner">
                            <h2
                              onClick={() =>
                                navigate(
                                  `/practice-single/${practice?.title}`,
                                  {
                                    state: {
                                      practice_id: practice.id,
                                    },
                                  }
                                )
                              }
                            >
                              <Link>{practice?.title}</Link>
                            </h2>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Practice;
